<template>
  <div class="view">
    <div class="content">
      <LogoBanner/>
      <div class="wrapper">
        <button class="btn-green qrButton btn" style="margin-top: 16px;" @click="$router.push('/qr/new')">
          <h5>
            Code erstellen
          </h5>
        </button>

        <h2 class="mt-3">Erstelle Codes</h2>

        <h5 v-if="!qrCodes.length" class="mt-3">
          Sie haben noch keine eigenen Codes erstellt!
        </h5>

        <b-list-group>
          <b-list-group-item href="#" class="flex-column align-items-start mt-3" variant="secondary" v-for="qrCode in qrCodes.slice().reverse()" :key="qrCode.id">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ qrCode.name }}</h5>
              <small>{{ (new Date(Date.parse(qrCode.date))).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'}) }}</small>
            </div>

            <p class="mb-1">
              {{ qrCode.address }}<br>
              Max: {{ qrCode.maxPerson }} Personen<br>
              Aufenthaltsdauer: {{ qrCode.duration }} Min.
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <menu-bar active-button="settings"></menu-bar>
  </div>
</template>

<script>
import LogoBanner from "../components/LogoBanner";
import MenuBar from "../components/MenuBar";
import {mapGetters} from "vuex";

export default {
  name: "OwnQRCode",
  components: {
    LogoBanner,
    MenuBar,
  },
  computed: {
    ...mapGetters([
        'getQRCodes'
    ]),
    qrCodes() {
      return this.getQRCodes
    }
  }
}
</script>

<style scoped>
h2 {
  text-align: center;
  font-weight: bold;
}
</style>
